<template>
	<div class="foot-bar">
		<van-tabbar v-model="active" @change="changeHandle">
			<van-tabbar-item name="1" icon="home-o">
				<span>首页</span>
				<template #icon="props">
					<img v-if="props.active" src="../../assets/images/index-a.png" alt="">
					<img v-else src="../../assets/images/index.png" alt="">
				</template>
			</van-tabbar-item>
			<van-tabbar-item name="2" icon="home-o">
				<span>我的医生</span>
				<template #icon="props">
					<img v-if="props.active" src="../../assets/images/my-doctor-a.png" alt="">
					<img v-else src="../../assets/images/my-doctor.png" alt="">
				</template>
			</van-tabbar-item>
			<!-- <van-tabbar-item name="3" icon="home-o">
				<span>消息中心</span>
				<template #icon="props">
					<img v-if="props.active" src="../../assets/images/msg-center-a.png" alt="">
					<img v-else src="../../assets/images/msg-center.png" alt="">
				</template>
			</van-tabbar-item> -->
			<van-tabbar-item name="4" icon="home-o">
				<span>我的</span>
				<template #icon="props">
					<img v-if="props.active" src="../../assets/images/mine-a.png" alt="">
					<img v-else src="../../assets/images/mine.png" alt="">
				</template>
			</van-tabbar-item>
		</van-tabbar>
	</div>
</template>
<script>
const pathMap = {
	1: '/',
	2: 'my-doctor',
	3: 'msg-center',
	4: 'user-center'
};

export default {
	data () {
		return {
			active: '1'
		};
	},
	created () {
		this.active = this.$route.meta.pathKey;
	},
	methods: {
		changeHandle (active) {
			this.$root.replace({ path: pathMap[active] });
		}
	},
	watch: {
		'$route.meta.pathKey': {
			handler (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.active = newVal;
				}
			},
			immediate: true,
			deep: true
		}
	}
};
</script>