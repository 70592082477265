var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _vm.$route.path !== "/"
        ? _c("router-view")
        : [
            _c(
              "div",
              { staticClass: "content" },
              [
                _c(
                  "van-swipe",
                  { staticClass: "s-swipe", attrs: { autoplay: "3000" } },
                  _vm._l(_vm.banners, function (banner, index) {
                    return _c(
                      "van-swipe-item",
                      {
                        key: index,
                        on: {
                          click: function ($event) {
                            return _vm.swiperClick(banner.href)
                          },
                        },
                      },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: banner.imgUrl,
                              expression: "banner.imgUrl",
                            },
                          ],
                        }),
                      ]
                    )
                  }),
                  1
                ),
                _c("div", { staticClass: "search-div" }, [
                  _c(
                    "div",
                    { staticClass: "search", on: { click: _vm.search } },
                    [
                      _c("i", { staticClass: "search-icon" }),
                      _c("span", [_vm._v("搜索不适、医生、科室等")]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "wrap flex f-ai-c" }, [
                  _c("div", { staticClass: "disease" }, [
                    _c(
                      "div",
                      { staticClass: "flex dis f-jc-sb" },
                      _vm._l(_vm.disease1, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "dis-item flex f-ai-c",
                            on: {
                              click: function ($event) {
                                return _vm.diseaseClick(item)
                              },
                            },
                          },
                          [
                            _c("img", { attrs: { src: item.img, alt: "" } }),
                            _c("span", [_vm._v(_vm._s(item.name))]),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "flex dis f-jc-sb" },
                      _vm._l(_vm.disease2, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "dis-item flex f-ai-c",
                            on: {
                              click: function ($event) {
                                return _vm.diseaseClick(item)
                              },
                            },
                          },
                          [
                            _c("img", { attrs: { src: item.img, alt: "" } }),
                            _c("span", [_vm._v(_vm._s(item.name))]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "doc" },
                    [
                      _c("div", { staticClass: "flex f-jc-sb doc-h f-ai-c" }, [
                        _c("h1", [_vm._v("名医推荐")]),
                        _c("span", { on: { click: _vm.toMydoctor } }, [
                          _vm._v(" 我的医生 "),
                          _c("i"),
                        ]),
                      ]),
                      _c(
                        "van-list",
                        {
                          attrs: {
                            finished: _vm.finished,
                            "finished-text": "没有更多了",
                            offset: "5",
                            "immediate-check": false,
                          },
                          on: { load: _vm.getDoctorRecommend },
                          model: {
                            value: _vm.loading,
                            callback: function ($$v) {
                              _vm.loading = $$v
                            },
                            expression: "loading",
                          },
                        },
                        _vm._l(_vm.doctors, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "doctor flex f-jc-sb",
                              on: {
                                click: function ($event) {
                                  return _vm.toDoctorDetail(item.id)
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: item.avatar || _vm.defDoc,
                                  alt: "",
                                },
                              }),
                              _c("div", { staticClass: "info" }, [
                                _c("div", { staticClass: "top flex f-jc-sb" }, [
                                  _c("div", { staticClass: "doctor-info" }, [
                                    _c("div", { staticClass: "flex name" }, [
                                      _c("span", [_vm._v(_vm._s(item.name))]),
                                      _c("span", [
                                        _vm._v(_vm._s(item.titleName)),
                                      ]),
                                    ]),
                                    _c("span", { staticClass: "hos" }, [
                                      _vm._v(_vm._s(item.hospitalName)),
                                    ]),
                                  ]),
                                  item.price !== undefined &&
                                  item.price !== null
                                    ? _c("span", { staticClass: "price" }, [
                                        _vm._v(
                                          "￥" + _vm._s(item.price) + "/次"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "doc-disease flex" },
                                  _vm._l(item.goodAt, function (ite, ind) {
                                    return _c("span", { key: ind }, [
                                      _vm._v(_vm._s(ite)),
                                    ])
                                  }),
                                  0
                                ),
                                _c("div", { staticClass: "app" }, [
                                  _c("span", [
                                    _c("i", [
                                      _vm._v(_vm._s(item.payCount || 0)),
                                    ]),
                                    _vm._v("人付款 "),
                                  ]),
                                  _c("span", [
                                    _c("i", [
                                      _vm._v(_vm._s(item.appraiseCount || 0)),
                                    ]),
                                    _vm._v("人评价 "),
                                  ]),
                                  _c("span", [
                                    _c("i", [
                                      _vm._v(_vm._s(item.goodApp || 100) + "%"),
                                    ]),
                                    _vm._v("好评 "),
                                  ]),
                                ]),
                                _c("div", { staticClass: "good-at" }, [
                                  _c("i", [_vm._v("擅长：")]),
                                  _c("div", [
                                    _vm._v(
                                      " " + _vm._s(item.goodAt.join("、")) + " "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ]),
                _c("a", {
                  staticClass: "customer-service",
                  attrs: { href: "tel:" + _vm.customerServiceMobile },
                }),
              ],
              1
            ),
          ],
      _c("footer-bar"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }