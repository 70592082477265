<template>
	<div class="home">
		<router-view v-if="$route.path !== '/'"></router-view>
		<template v-else>
			<div class="content">
				<van-swipe class="s-swipe" autoplay="3000">
					<van-swipe-item v-for="(banner, index) in banners" :key="index" @click="swiperClick(banner.href)">
						<img v-lazy="banner.imgUrl" />
					</van-swipe-item>
				</van-swipe>
				<div class="search-div">
					<div class="search" @click="search">
						<i class="search-icon"></i>
						<span>搜索不适、医生、科室等</span>
					</div>
				</div>
				<div class="wrap flex f-ai-c">
					<!-- 疾病列表 -->
					<div class="disease">
						<div class="flex dis f-jc-sb">
							<div v-for="(item, index) in disease1" :key="index" class="dis-item flex f-ai-c" @click="diseaseClick(item)">
								<img :src="item.img" alt="">
								<span>{{item.name}}</span>
							</div>
						</div>
						<div class="flex dis f-jc-sb">
							<div v-for="(item, index) in disease2" :key="index" class="dis-item flex f-ai-c" @click="diseaseClick(item)">
								<img :src="item.img" alt="">
								<span>{{item.name}}</span>
							</div>
						</div>
					</div>
					<!-- 名医推荐 -->
					<div class="doc">
						<div class="flex f-jc-sb doc-h f-ai-c">
							<h1>名医推荐</h1>
							<span @click="toMydoctor">
								我的医生
								<i></i>
							</span>
						</div>
						<van-list
							:finished="finished"
							finished-text="没有更多了"
							offset="5"
							v-model="loading"
							@load="getDoctorRecommend"
							:immediate-check="false"
						>
							<div v-for="(item, index) in doctors" :key="index" class="doctor flex f-jc-sb" @click="toDoctorDetail(item.id)">
								<img :src="item.avatar || defDoc" alt="">
								<div class="info">
									<div class="top flex f-jc-sb">
										<div class="doctor-info">
											<div class="flex name">
												<span>{{item.name}}</span>
												<span>{{item.titleName}}</span>
											</div>
											<span class="hos">{{item.hospitalName}}</span>
										</div>
										<span class="price" v-if="item.price !== undefined && item.price !== null">￥{{item.price}}/次</span>
									</div>
									<div class="doc-disease flex">
										<span v-for="(ite, ind) in item.goodAt" :key="ind">{{ite}}</span>
									</div>
									<div class="app">
										<span>
											<i>{{item.payCount || 0}}</i>人付款
										</span>
										<span>
											<i>{{item.appraiseCount || 0}}</i>人评价
										</span>
										<span>
											<i>{{item.goodApp || 100}}%</i>好评
										</span>
									</div>
									<div class="good-at">
										<i>擅长：</i>
										<div>
											{{item.goodAt.join('、')}}
										</div>
									</div>
								</div>
							</div>
						</van-list>
					</div>
				</div>
				<a :href="`tel:${customerServiceMobile}`" class="customer-service"></a>
			</div>
		</template>
		<footer-bar />
	</div>
</template>
<script>
import footerBar from '../common/footerBar';
import { bannerList, diseaseRecommend, customerService } from '@/request/api/user';
import { doctorRecommend } from '@/request/api/doctor';
export default {
	data () {
		return {
			customerServiceMobile: '',
			defDoc: require('@/assets/images/doctor-def.png'),
			banners: [],
			disease1: [],
			disease2: [],
			finished: false,
			loading: false,
			doctors: [],
			list: [],
			page: 1
		};
	},
	components: {
		footerBar
	},
	created () {
		this.getCustomerService();
		this.getDoctorRecommend();
		this.getDiseaseRecommend();
		this.getBanner();
	},
	methods: {
		swiperClick (href) {
			this.$root.go(href);
		},
		search () {
			this.$root.go('/search');
		},
		getBanner () {
			bannerList({ end: 2 }).then(data => {
				this.banners = data.banners;
			});
		},
		getCustomerService () {
			customerService().then(data => {
				if (data && data === 'retry') {
					this.getCustomerService();
				} else if (data) {
					this.customerServiceMobile = data.mobilePhone;
				}
			});
		},
		getDoctorRecommend () {
			this.loading = true;
			doctorRecommend({ page: this.page, limit: 10 }).then(data => {
				if (data && data === 'retry') {
					this.getDoctorRecommend();
				} else if (data && data.length) {
					const _doc = data.map(d => {
						let price = 0;
						for (let i = 0; i < d.services.length; i++) {
							if (d.services[i].price > price) {
								price = d.services[i].price;
							}
						}
						d.price = price;
						return d;
					});
					this.doctors = this.doctors.concat(_doc);
					this.page++;
				} else {
					this.finished = true;
				}
				this.loading = false;
			}).catch(e => {
				this.$root.elMsg(e.message, 'fail');
			});
		},
		getDiseaseRecommend () {
			diseaseRecommend().then(data => {
				if (data && data === 'retry') {
					this.getDiseaseRecommend();
				} else if (data && data.length) {
					data.forEach((d, index) => {
						if (index <= 3) {
							this.disease1.push({
								name: d.name,
								img: d.imgUrl
							});
						} else {
							this.disease2.push({
								name: d.name,
								img: d.imgUrl
							});
						}
					});
					this.disease2.push({
						img: require('@/assets/images/more.png'),
						name: '更多',
						key: 'more'
					});
				}
			});
		},
		toMydoctor () {
			this.$root.go('/my-doctor');
		},
		diseaseClick (item) {
			if (item.key === 'more') {
				this.$root.go('/department');
			} else {
				this.$root.go('/search', { diseaseName: item.name });
			}
		},
		toDoctorDetail (id) {
			this.$root.go('/doctor-detail', { doctorId: id });
		}
	}
};
</script>
<style lang="less" scoped>
	i, span {
		display: inline-block;
	}
	.home {
		position: relative;
		background: #FAFAFA;
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow: hidden;
		justify-content: flex-start;
		.content {
			flex: 1;
			overflow: auto;
			position: relative;
			// padding-bottom: 74px;
			.customer-service {
				position: fixed;
				right: 0;
				bottom: 95px;
				background: url('../../assets/images/customer-service.png') no-repeat;
				background-size: contain;
				width: 63px;
				height: 72px;
			}
		}
		.foot-bar {
			display: block;
		}
		/deep/.van-swipe {
			height: 170px !important;
			position: relative;
		}
		.s-swipe {
			.van-swipe-item {
				height: 170px !important;
				img {
					width: 100%;
					height: 100%;
					object-fit: fill;
				}
			}
		}
		.search-div {
			display: flex;
			align-items: center;
			justify-items: center;
			width: 100%;
			position: absolute;
			top: 149px;
		}
		.search {
			margin: 0 12px;
			flex: 1;
			height: 40px;
			line-height: 40px;
			border-radius: 20px;
			background: #FFFFFF;
			box-shadow: 0px 1px 12.5px 0px rgba(0, 0, 0, 0.06);
			border: none;
			padding: 0 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			color: #959595;
			font-size: 13px;
			font-weight: 400;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			.search-icon {
				background-image: url('../../assets/images/search-icon.png');
				background-size: contain;
				width: 14px;
				height: 14px;
			}
			span {
				margin-left: 5px;
			}
		}
		.wrap {
			flex-direction: column;
			padding-bottom: 74px;
			.disease {
				width: 100%;
				background-color: #FFFFFF;
				border-radius: 6px;
				margin-top: 39px;
				padding-bottom: 4px;
				.dis {
					padding: 0 15px;
					&:nth-child(1) {
						margin: 15px 0 20px 0;
					}
					&:nth-child(2) {
						margin-bottom: 20px;
					}
					&-item {
						width: 55px;
						flex-direction: column;
						img {
							width: 55px;
							height: 55px;
							margin-bottom: 5px;
							border-radius: 50%;
						}
						span {
							font-size: 13px;
						}
					}
				}
			}
			.doc {
				width: 100%;
				&-h {
					margin-top: 10px;
					height: 40px;
					h1 {
						height: 17px;
						font-size: 17px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #262626;
						line-height: 17px;
					}
					span {
						font-size: 13px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #7D7D7D;
						i {
							width: 5px;
							height: 9px;
							margin-left: 2px;
							background: url('../../assets/images/right-arrow.png') no-repeat;
							background-size: contain;
						}
					}
				}
				.doctor {
					width: 100%;
					background-color: #FFFFFF;
					border-radius: 6px;
					margin-bottom: 10px;
					padding: 10px 10px 20px;
					box-sizing: border-box;
					img {
						width: 50px;
						height: 50px;
						border-radius: 50%;
						object-fit: cover;
						margin-right: 10px;
					}
					.info {
						// width: 270px;
						flex: 1;
						.top {
							.doctor-info {
								.name {
									font-size: 16px;
									color: #232830;
									span {
										margin-right: 10px;
									}
									span:nth-child(1) {
										white-space: nowrap;
										font-family: PingFangSC-Medium, PingFang SC;
									}
									span:nth-child(2) {
										font-family: PingFangSC-Regular, PingFang SC;
									}
								}
								.hos {
									font-size: 13px;
									font-family: PingFangSC-Regular, PingFang SC;
									font-weight: 400;
									color: #7D7D7D;
									margin-top: 3.5px;
								}
							}
							.price {
								font-size: 14px;
								font-family: PingFangSC-Medium, PingFang SC;
								font-weight: 500;
								white-space: nowrap;
								color: #F33D3A;
							}
						}
						.doc-disease {
							margin-top: 16px;
							flex-wrap: wrap;
							span {
								// height: 20px;
								background: #F7F7F7;
								border-radius: 10px;
								line-height: 20px;
								font-size: 11px;
								font-family: PingFangSC-Regular, PingFang SC;
								font-weight: 400;
								color: #3B3D40;
								margin: 0 15px 7px 0;
								padding: 0 8px;
								box-sizing: border-box;
							}
						}
						.app {
							height: 25px;
							background: #FFF9F2;
							border-radius: 2px;
							line-height: 25px;
							padding-left: 15px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #9396A0;
							font-size: 10px;
							span {
								margin-right: 15px;
								i {
									font-size: 14px;
									font-family: PingFangSC-Medium, PingFang SC;
									font-weight: 500;
									color: #FF8D16;
									margin-right: 4px;
								}
							}
						}
						.good-at {
							margin-top: 8px;
							position: relative;
							font-size: 12px;
							i {
								position: absolute;
								color: #232830;
								font-weight: 600;
							}
							div {
								text-indent: 50px;
								color: #9396A0;
								word-break: break-all;
								text-overflow: ellipsis;
								overflow: hidden;
								display: -webkit-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical;
							}
						}
					}
				}
			}
		}
	}
</style>